




















































import { Component, Ref, Vue } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { DialogService } from '@/services/common/dialog.service';
import { I18nService } from '@cds/i18n';
import { uuid } from '@/services/utils';
import AddManagerUser from '@/components/addManagerUser/addManagerUser.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { rulesConfig } from '@/components/NovaGroupTransfer/index.config';
import { IAdminUser } from '@/views/manager';
import { UserGroupService } from '@/services/user-group-service';
import { ElForm } from 'element-ui/types/form';

@Component({
  components: {}
})
export default class ModifyUserGroupCustom extends Vue {
  public fileName: string = '';
  private data!: any;
  private formLoading: boolean = false;
  private formData: any = {};
  public adminList: any[] = [];
  public rules: any = rulesConfig;
  private currentUser!: IAdminUser;
  private $dialogInstance!: any;
  public isUpLoad = false;

  @Ref('ruleForm') private ruleForm!: ElForm;


  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(CacheService) private cacheSvc!: CacheService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<void> {
    this.currentUser = this.cacheSvc.get('user');
    this.formData = {
      file: [],
      groupName: '',
      owners: '',
      customGroupId: '',
    }
    this.adminList = [
      {
        disabledFlag: 1,
        email: this.currentUser.email,
        employeeId: this.currentUser.employeeId || '',
        username: this.currentUser.fullName,
        id: this.currentUser.id
      }
    ]
  }

  public async mounted(): Promise<void> {  
    if (this.data.userGroupId) {
      await this.getUserGroupDetail(this.data.userGroupId)
    }
  } 

  // 上传文件
  public async handleChange(file: any) {
    this.formData.file = [file];
    this.checkFile();
  }

  public checkFile() {
    if (this.formData.file && this.formData.file.length > 0) {
      this.isUpLoad = false;
    } else {
      this.isUpLoad = true;
    }
    return this.isUpLoad;
  }

  // 文件被移除后的回调函数
  public upLoadRemove(file: any) {
    this.formData.file = [];
    this.checkFile();
  }

  // 在移除文件前的回调函数
  public beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  //下载模板
  public async downLoadAll() {
    this.formLoading = true;
    await this.userGroupService.downLoadTemplate('自定义分组模板.xlsx');
    this.formLoading = false;
  }

  //编辑时查询详情
  private async getUserGroupDetail(id: string) {
    try {
      this.formLoading = true;
      const res = await this.userGroupService.getCustomGroupUsers({customGroupId: id});
      this.formData = res;
      this.adminList = res.owners;
      this.formData.file = [];
    } catch {
      this.$notify({
        title: '提示',
        message: '获取分组信息遇到问题，请稍后再试',
        type: 'error'
      });
      this.dialog.close(CloseStatus.close, {}, this.data.key);
    } finally {
      this.formLoading = false;
    }
  }

  // 添加管理员
  public async addManagerUser(): Promise<void> {
    const key = uuid();
    this.$dialogInstance.hide();
    const res = await this.dialog.open(
      '选择管理员',
      AddManagerUser,
      {
        list: this.adminList,
        key
      },
      {
        height: 'auto',
        width: 'auto',
        key
      }
    );
    this.$dialogInstance.show();

    if (res.action === CloseStatus.confirm) {
      const result = res.result.map((item:any) => {
        if(!item.username) {
          item.username = item.fullName
        }
        return item;
      })
      this.adminList = result;
    }
  }

  // 删除管理人员
  public handleTagDelete(obj: any, data: any, key: string): void {
    const index = this.adminList.findIndex((item: any) => item[key] === obj[key]);
    this.adminList.splice(index, 1);
  }

  //保存
  public async submitSaved(): Promise<void> {
    const hasFile = this.checkFile();
    const isValid = await this.ruleForm.validate();
    if(isValid && !hasFile) {
        const param = { 
          ...this.formData,
          owners: (this.adminList || []).map((item: any) => {
            return item.employeeId;
          }).join(',')
        }
      if(this.formData.customGroupId) {
        const res = await this.userGroupService.updateCustomGroup(param);
        if(res.failureEmails && res.failureEmails.length > 0) {
          const msg = res.failureEmails.join('<br>') + '<br>' +res.failureMessage;
          this.$notify({
            title: '成功',
            dangerouslyUseHTMLString: true,
            message: msg,
            type: 'success',
          });
        } else {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
          });
        }
        this.dialog.close(CloseStatus.confirm, { formData: res }, this.data.key); 
      } else {
        const isGroupNameRepeat = await this.userGroupService.customGroupCheck({ groupName: param.groupName });
        if(!isGroupNameRepeat) {
          this.$notify({
            title: '失败',
            message: '用户组名称重复',
            type: 'error'
          });
        } else {
          const res = await this.userGroupService.addCustomGroup(param);
          if(res.failureEmails && res.failureEmails.length > 0) {
          const msg = res.failureEmails.join('<br>') + '<br>' +res.failureMessage;
            this.$notify({
              title: '成功',
              message: msg,
              dangerouslyUseHTMLString: true,
              type: 'success'
            });
          } else {
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success'
            });
          }
          this.dialog.close(CloseStatus.confirm, { formData: res }, this.data.key); 
        }
      }
    }
  }

  //取消
  public async cancelSave() {
    await this.$confirm('当前信息将不会被保存，请确认是否取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    });
    this.dialog.close(CloseStatus.close, {}, this.data.key);
  }

}

