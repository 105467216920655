
































































import { Component, Vue } from 'vue-property-decorator';
import UserGroupCustomDetail from '@/views/userGroup/UserGroupCustomDetail.vue';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserGroupService } from '@/services/user-group-service';
import ModifyUserGroupCustom from '@/components/UserGroup/ModifyUserGroupCustom.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';
import UserGroupDetailTable from '@/components/UserGroup/UserGroupDetailTable.vue';
import UserGroupTagGroup from '@/components/UserGroup/UserGroupTagGroup.vue';
import UserGroupTagGroupCustom from '@/components/UserGroup/UserGroupTagGroupCustom.vue';
import { cloneDeep } from 'lodash';

@Component({ components: { UserGroupDetailTable, UserGroupCustomDetail, UserGroupTagGroup, UserGroupTagGroupCustom } })
export default class UserGroupCustom extends Vue {
  private loading: boolean = false;
  private searchText: string = '';
  public allGroups: any[] = [];
  private activeUserGroup: any[] = [];

  private isFilteredUserGroup: boolean = false;
  private isSelectedFilterUserGroupShow: boolean = false;
  public filteredUserList: any = [];
  public groupId: any = '';


  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!:DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<any> {
    await this.initAllUserGroups();
  }

  //查询所有自定义组
  public async initAllUserGroups(): Promise<void> {
    this.loading = true;
    try {
      this.allGroups = await this.userGroupService.getCustomGroupList();
    } finally {
      this.loading = false;
    }
  }
    // 切换排序
  public changeSortType(value:any){
    console.log('zlw', value)
    let availableGroups = cloneDeep(this.allGroups);
    if (value == 'name') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        return (a.groupName).localeCompare(b.groupName);
      });
    } else if (value == 'name-d') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        return (b.groupName).localeCompare(a.groupName);
      });
    } else if (value == 'createTime') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        if(a && b){
          let timeA = Date.parse(a.createTime)
          let timeB = Date.parse(b.createTime)
          return timeA - timeB;
        }
        return  false
      });
    } else if (value == 'createTime-d') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        if(a && b){
          let timeA = Date.parse(a.createTime)
          let timeB = Date.parse(b.createTime)
          return timeB - timeA;
        }
        return  false
      });
    }
  }

  //搜索
  private async searchFilteredRules() {
    if (this.searchText !== '') {
      let filteredData = await this.userGroupService.searchCustomGroupAndUser({ keyword: this.searchText ? this.searchText : '' });
      console.log('filteredData', filteredData);
      if(filteredData) {
        this.allGroups = filteredData.groups;
        this.activeUserGroup = filteredData.groups;
        this.filteredUserList = filteredData.users;
      }
      this.isSelectedFilterUserGroupShow = false;
      this.isFilteredUserGroup = true;
    } else {
      this.isFilteredUserGroup = false;

    }
  }

  // 新建自定义分组
  private async createNewGroup(): Promise<void> {
    const key = uuid();
    const res = await this.dialogService.open(
      '创建自定义用户分组',
      ModifyUserGroupCustom,
      {
        config: [
          {}
        ],
        type:'hr core',
        key
      },
      {
        height: 'auto',
        key
      }
    );
    console.log(res);
    if (res.action === CloseStatus.confirm) {
      await this.initAllUserGroups();
      let activeUserGroup = [this.allGroups[0]];
      activeUserGroup[0].refreshTime = Date.now();
      this.activeUserGroup = activeUserGroup;
      this.isFilteredUserGroup = false;
      this.searchText = '';
    }
  }

  //清空搜索框
  private async clearSearch(val: any) {
    if (!this.searchText){
      this.activeUserGroup = [];
      this.isFilteredUserGroup = false;
      await this.initAllUserGroups();
    }
  }

  //点击分组标签时更新用户名列表
  private changeActiveUserGroup(val: any) {
    console.log('changeActiveUserGroup', this.activeUserGroup);
    let newVal = cloneDeep(val);
    newVal.refreshTime = Date.now();
    this.groupId = newVal.customGroupId;
    if (this.isFilteredUserGroup) {
      this.isSelectedFilterUserGroupShow = true;
    }
    this.activeUserGroup = [newVal];
  }

  //刷新用户名列表
  public async refreshUserGroupList(value:any){
    await this.initAllUserGroups();
    let activeUserGroup = [this.allGroups[0]];
    activeUserGroup[0].refreshTime = Date.now();
    this.activeUserGroup = activeUserGroup;
    this.isFilteredUserGroup = false;
  }

  //修改用户组数据时
  public async modifyUserGroupConfirm(): Promise<void> {
    this.loading = true;
    try {
      this.allGroups = await this.userGroupService.getCustomGroupList();
    } finally {
      this.loading = false;
    }
  }

}
