















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserGroupService } from '@/services/user-group-service';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import ModifyUserGroupCustom from '@/components/UserGroup/ModifyUserGroupCustom.vue'
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';

@Component({
  components: {}
})
export default class UserGroupTagGroupCustom extends Vue {
  public text: String = '';
  public btnBalg: Boolean = false;
  public groupList:any ='';
  private loading: boolean = false;
  private allAuthorizedUserGroups: any[] = [];
  private sortName = 'name';
  private options = [
    { label: '按名称正序查看', value: 'name' },
    { label: '按名称逆序查看', value: 'name-d' },
    {label: "按创建时间正序查看",value: "createTime"},
    {label: "按创建时间逆序查看",value: "createTime-d"}
  ];

  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;

  @Prop({ required: true })
  private allGroups: any;
  @Prop({ required: true })
  private activeUserGroup: any;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get isFoldTag(){
    return this.activeUserGroup.length === 1
  }

  public async created(): Promise<any> {
    this.allAuthorizedUserGroups = await this.userGroupService.getAllUserGroups();
  }

  public isActiveUserGroup(id: string): Boolean {
    return !!this.activeUserGroup.find((item: any) => item.customGroupId == id);
  }

  // 编辑分组
  public async updateUserGroup(item: any): Promise<void> {
    console.log('updateUserGroup', item);
    const key = uuid();
    const res = await this.dialogService.open(
      '编辑自定义用户分组',
      ModifyUserGroupCustom,
      {
        config: [{}],
        userGroupId: item.customGroupId,
        key
      },
      {
        height: 'auto',
        key
      }
    );
    if (res.action === CloseStatus.confirm) {
      this.$emit('modifyUserGroupConfirm');
      this.$emit('changeActiveUserGroup', item);
    }
  }

  // 点击组名
  private clickTag(val: any) {
    console.log('click', val);
    const { customGroupId } = val;
    this.allGroups.forEach((group: any) => {
      if (group.customGroupId == customGroupId) {
        this.$emit('changeActiveUserGroup', group);
      }
    });
  }

  // 删除分组
  private async handleClose(item: any): Promise<void> {
    let message: string = '确定要删除吗?';
    try {
      await this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      });
      const res: boolean = await this.userGroupService.deleteCustomGroup({customGroupId: item.customGroupId});
      if(res) {
        this.$notify.success({
        title: '删除',
        message: '删除成功'
      });
      }
      if (item.customGroupId.toString() === this.activeUserGroup[0].customGroupId.toString()) {
        this.$emit('refreshUserGroupList', item);
        this.$emit('changeActiveUserGroup', this.allGroups[0]);
      }
    } catch (e) {
      this.$notify.info({
        title: '取消',
        message: '删除取消'
      });
    }
  }

  //点击 编辑分组或删除分组
  public handleCommand(action: string, item: any, index: number): void {
    this.$set(this.allGroups, index, { ...item, popoverVisible: false });
    if (action === 'edit') {
      this.updateUserGroup(item);
    } else if (action === 'delete') {
      this.handleClose(item);
    }
  }

  // 点击菜单
  public async clickMenu(event: any, item: any) {
    let res = await this.userGroupService.getCustomGroupUsers({customGroupId: item.customGroupId});
    this.groupList = res.users; 
    this.text = ''
    this.btnBalg = false;
    event.preventDefault();
    event.stopPropagation();
    item.popoverVisible = true;  
  }

  // 切换排序
  public changeSortType(value:any){
    this.$emit('changeSortType', value);
  }
}
