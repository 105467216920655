



















































































































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import OptionsTemp from './OptionsTemp.vue';
import { UserGroupService } from '@/services/user-group-service';
import { DepartmentService } from '@/services/department-service';
import {
  isString,
  debounce,
  isArray,
  some,
  lowerCase,
  cloneDeep,
  forEach,
  filter,
  find,
  findIndex,
  isEqual
} from 'lodash';
import { hrCoreConfig } from './index.config';
import HrOptionsTemp from './hrOptionsTemp.vue'

@Component({
  components: { OptionsTemp, HrOptionsTemp }
})
export default class HrcorePositionTransfer extends Vue {
  public loading: boolean = false;
  public searchLoading: boolean = false;
  public leftSearch: string = '';
  public rightSearch: string = '';
  public leftisIndeterminate: boolean = true;
  public rightisIndeterminate: boolean = true;
  public placeholder: string = '搜索部门或员工';
  // public quickQueryUserList = debounce(this.queryUser, 800);
  public removedDropdownlist: Dict<boolean> = {};
  public searchConfig: Dict<any> = [];
  private fillIntervalId!: number;
  private formData: any = {};
  private groupId: any = '';
  // 初始化数组
  private leftdata: any = [];
  private rightdata: any = [];
  private leftCheckList: any = [];
  private rightCheckList: any = [];
  // 左侧可选
  private leftcheckAll: boolean = false;
  private rightcheckAll: boolean = false;
  private searchResults!: Dict<any[]>;
  private searchForm: any = {};
  private initDataCount!: number; // 通过搜索获取的数组长度 每次搜索后重新赋值
  private isSearch: boolean = true; // 是否搜索的道德flag 默认false


  // =================== HR CORE ORG ======================
  public departmentTree: any = [];
  public defaultProps = {
    children: 'children',
    label: 'name'
  }
  public rightCheckDataList: any = []
  public leftCheckDataList: any = []
  public defaultCheckedKeys: any = []
  public defaultExpandedKeys: any = []
  public defaultDisabledDepartmentID: any = [];
  public effectMemebersCount: any = 0
  public dialogTableVisible: boolean = false
  public currentDepartment: string = ''
  public currentDepartMembers: any = []
  public filterText: string = ''
  public memberForm = {
    name: '',
    employeeNumber: ''
  }
  public currentPage: number = 1
  public pageSize: number = 10
  public totalCount: number = 0
  public deptSearchList: any = []
  public memberSearchList: any = []
  public deptCheckList: any = []
  public memberCheckList: any = []
  public showDropSearch: boolean = false; // 搜搜菜单

  // ========================================


  // @Prop({ required: true })
  // private userGroupRoles!: Dict<any>;

  @Prop({ required: true })
  private type!: string;


  @Model('change', { type: Object, default: () => {{}} })
  private formInitData!: any;

  @Inject(UserGroupService)
  private userGroupService!: UserGroupService;

  @Inject(DepartmentService)
  private departmentService!: DepartmentService;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;


  // @Watch('groupId')
  // public async handleGroupChange(valve: any): Promise<void> {
  //   await this.$nextTick();
  //   this.filterText = '';
  //   this.clearSearch();
  //   this.disabledChildrenOfData(this.departmentTree, false);
  //   this.getDefaultCheckedKeys(this.rightdata, this.departmentTree);
  //   this.departmentTree =cloneDeep(this.departmentTree);
  // }

  // @Watch('rightInitData')
  // public handleinitRightData(value: Dict<any>): void {
  //   console.log('watch rightInitData');
  //   this.rightdata = value;
  //   // this.departmentTree = cloneDeep(this.departmentTree);
  //
  // }

  @Watch('formInitData')
  public handleinitFormData(value: Dict<any>): void {
    console.log('watch rightInitData', value);
    this.rightdata = value.users ;
    if ( value.id !== this.groupId) {
      this.filterText = '';
      this.clearSearch();
      this.disabledChildrenOfData(this.departmentTree, false);
      this.getDefaultCheckedKeys(value.users, this.departmentTree);
      this.departmentTree = cloneDeep(this.departmentTree);
    }
    this.groupId = value.id;
    this.formData = value;
  }

  // @Watch('userGroupRoles')
  // public async handleinitUserGroupRoles(value: Dict<any>): Promise<void> {
  //   this.showDropSearch = true;
  //   // this.searchForm = {};
  //   await this.getSearchOption({});
  //   this.leftSearch = '';
  //   this.searchForm = value;
  //   this.removedDropdownlist = {};
  //   forEach(this.searchForm, (item, key) => {
  //     this.removedDropdownlist[key] = isArray(item) && item.length > 0;
  //   });
  // }

  // ===============================HR CORE ORG==================================



  // ===========================================

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get filterEffectUserNumbers() {
    if (isArray(this.rightdata) && this.rightdata.length > 0) {
      const res = this.rightdata.reduce(
        (total: number, currentValue: Dict<any>) =>
          total + currentValue.effectUserNumbers,
        0
      );
      return res;
    }
    return 0;
  }

  public getLabel(
    options: Dict<any>[],
    val: string,
    key: string,
    removeUnexists?: boolean
  ): string | undefined {
    const target = find(options, (e) => e.value === val);
    if (!target) {
      const index = findIndex(this.searchForm[key], (e) => e === val);
      if (index > -1) {
        if (removeUnexists) {
          this.searchForm[key].splice(index, 1);
        }
        if (this.searchForm[key].length === 0) {
          this.removedDropdownlist[key] = false;
        }
      }
      return;
    } else {
      return target.label || target.value;
    }
  }

  public clearSearch():void{
    if (!this.filterText){
      this.showDropSearch = false
      this.getDefaultCheckedKeys(this.rightdata, this.departmentTree)
    }
  }

  // 搜索dropdown
  public dropdownSearch(): void {
    if (this.filterText) {
      this.showDropSearch = true
      this.deptCheckList = []
      this.memberCheckList = []
      this.queryUserAndDepartByText()
    } else {
      this.showDropSearch = false
      this.getDefaultCheckedKeys(this.rightdata, this.departmentTree)
    }
  }

  // 左侧逻辑
  // public leftCheckAllChange(val: boolean): void {
  //   this.leftCheckList = val ? this.leftdata.slice(0) : [];
  //   this.leftcheckAll = val;
  // }
  // 移动到左侧
  // public moveToLeft(): void {
  //   this.rightcheckAll = this.rightcheckAll
  //     ? !this.rightcheckAll
  //     : this.rightcheckAll;
  //   this.rightCheckList.forEach((item: any) => {
  //     const index = this.rightdata.findIndex(
  //       (rightItem: any) => rightItem.userId === item.userId
  //     );
  //     this.rightdata.splice(index, 1);
  //     // 从右侧穿梭回来的数据要恢复原有的生效人数 effectUserNumbers 等于 effectUserNumbers 缓存人数
  //     item.effectUserNumbers = item.effectUserNumbers;
  //     this.leftdata.push(item);
  //   });
  //   this.rightdata.forEach((item: any) => {
  //     // 如果筛选出来的结果 穿梭到左边那么右边的 flag为N的生效人数全部置为1
  //     if (item.quickSearchFlag === 'N') {
  //       item.effectUserNumbers = 1;
  //     }
  //   });
  //   this.$emit('change', this.rightdata);
  //   this.rightCheckList = [];
  // }

  // 右侧逻辑
  // public rightCheckAllChange(val: boolean): void {
  //   this.rightCheckList = val ? this.rightdata.slice(0) : [];
  //   this.rightisIndeterminate = false;
  // }
  // 数据移动到右侧
  // public moveToRight(): void {
  //   this.leftcheckAll = this.leftcheckAll
  //     ? !this.leftcheckAll
  //     : this.leftcheckAll;
  //   this.leftCheckList.forEach((item: any) => {
  //     const index = this.leftdata.findIndex(
  //       (leftItem: any) => leftItem.userId === item.userId
  //     );
  //     // 通过搜索查询的结果 isSearch 且 全部穿梭 与 initDataCount 搜索得到的数组长度相等 不需要改变任何数据
  //     // 通过搜索查询的结果 isSearch 且 全部穿梭 与 initDataCount 搜索得到的数组长度不相等 穿梭过去的生效人数为1
  //     if (this.isSearch && this.initDataCount !== this.leftCheckList.length) {
  //       item.effectUserNumbers = 1;
  //       this.$emit('flag', 'N');
  //     }
  //     this.rightdata.push(item);
  //     if (index !== -1) {
  //       this.leftdata.splice(index, 1);
  //     }
  //   });
  //   // 分条传送的 如果最后右边的数组长度等于初始长度那么 所有右边生效人数取缓存生效人数
  //   if (this.isSearch && this.rightdata.length === this.initDataCount) {
  //     // 设置有结构
  //     this.$emit('flag', 'Y');
  //     this.rightdata.forEach((item: any) => {
  //       item.effectUserNumbers = item.effectUserNumbers;
  //     });
  //   }
  //   this.$emit('change', this.rightdata);
  //   this.leftCheckList = [];
  //
  // }

  // public rightSearchChange(val: string): void {
  //   // debugger
  //   if (!val) {
  //     this.rightdata = cloneDeep(this.rightInitData);
  //   } else {
  //     this.rightdata = this.rightInitData.filter((item: any) => {
  //       return some(
  //         [item.name, item.employeeNumber, item.email, item.positionLine],
  //         (e) => e && lowerCase(e).indexOf(lowerCase(val)) > -1
  //       );
  //     });
  //   }
  // }

  // 搜索确定
  // public async confirmSearch(): Promise<void> {
  //   // 筛选确定后删除右边穿梭数据
  //   // 通过搜索查询 isSearch 置为true
  //   this.rightCheckList = [];
  //   this.searchForm.division = this.type.toLowerCase();
  //   await this.queryuserlist(this.searchForm, true);
  //   this.isSearch = true;
  //   this.showDropSearch = false;
  //   this.$emit('handleChangeRoles', this.searchForm);
  // }

  // public resetSearch(): void {
  //   forEach(this.searchForm, (item, key) => {
  //     if (key !== 'division') {
  //       this.searchForm[key] = [];
  //     }
  //   });
  //   this.removedDropdownlist = {};
  //   this.getSearchOption();
  // }

  // public handleCloseTag(index: number, propKey: string): void {
  //   if (
  //     isArray(this.searchForm[propKey]) &&
  //     this.searchForm[propKey].length > 0
  //   ) {
  //     this.searchForm[propKey].splice(index, 1);
  //     this.handleNoTag(this.searchForm[propKey], propKey);
  //   }
  // }

  // public handleOptionsChange(isOpen: boolean, propKey: string): void {
  //   // if (isOpen) {
  //   //   const target = find(this.searchConfig, (item) => item.prop === propKey);
  //   //   target.options = this.searchResults[propKey];
  //   // }
  //   if (
  //     !isOpen &&
  //     isArray(this.searchForm[propKey]) &&
  //     this.searchForm[propKey].length > 0
  //   ) {
  //     this.removedDropdownlist[propKey] = true;
  //     this.getSearchOption();
  //   }
  // }

  // public handleNoTag(val: string[], propKey: string): void {
  //   if (isArray(val) && val.length === 0) {
  //     this.removedDropdownlist[propKey] = false;
  //     this.getSearchOption();
  //   }
  // }
  public destroy(): void {
    clearInterval(this.fillIntervalId);
  }
  public created(){
    this.formData = this.formInitData;
    console.log('created',this.formData)
  }
  public async mounted(): Promise<void> {
    // debugger
    await this.initRightData();
    await this.initEmployeeData();

    // this.handleinitUserGroupRoles(this.userGroupRoles);
  }
  // 查询筛选列表
  // private async queryuserlist(
  //   searchParam: any,
  //   isAllChecked?: boolean
  // ): Promise<void> {
  //   const res = (await this.userGroupService.postusergroup(searchParam)) || [];
  //   this.leftdata = res.slice(0);
  //   if (isAllChecked) {
  //     this.leftCheckAllChange(true);
  //   }
  //   // 初始化data数组length
  //   this.initDataCount = res.length;
  //   this.initRightData('search');
  // }

  // private async queryUser(event: any): Promise<void> {
  //   this.leftCheckList = [];
  //   const keyword = isString(event)
  //     ? event
  //     : (event as any).currentTarget.querySelector('input').value;
  //   if (keyword) {
  //     let division: string;
  //     switch (this.type) {
  //       case 'Pharma':
  //         division = '0';
  //         break;
  //       case 'Onco':
  //         division = '1';
  //         break;
  //       case 'HR Core':
  //         division = '2';
  //         break;
  //       default:
  //         division = '0';
  //     }
  //     try {
  //       this.loading = true;
  //       const res = await this.userGroupService.getusergroupQuickusers({
  //         division,
  //         keyword
  //       });
  //
  //       this.leftdata = res || [];
  //       this.leftCheckList = filter(this.leftCheckList, (e) =>
  //         some(this.leftdata, (el) => el.userId === e.userId)
  //       );
  //       this.initRightData();
  //     } finally {
  //       this.loading = false;
  //     }
  //   } else {
  //     this.leftdata = [];
  //   }
  //   this.isSearch = false;
  // }


  // 初始化右侧列表
  private initRightData(type?: string): void {
    // 初始化type有值的时候 通过搜索初始化右边穿梭 初始化type没有值的时候常规初始化
    if (!type) {
      this.rightdata = this.formInitData.users ? this.formInitData.users: [];
    } else {
      // this.rightInitData = [];
      this.rightdata = [];
    }

    this.rightdata.forEach((outitem: any) => {
      const index = this.leftdata.findIndex(
        (item: any) => item.userId === outitem.userId
      );
      if (index !== -1) {
        this.leftdata.splice(index, 1);
      }
    });
  }

  // 获取筛选下拉
  // private async getSearchOption(val?: Dict<any>): Promise<void> {
  //   this.searchConfig = hrCoreConfig;
  //   this.searchResults = await this.userGroupService.postcondition(
  //     this.type === 'Pharma' ? 'pharma' : 'onco',
  //     val ? val : this.searchForm
  //   );
  //   // const keys = Object.keys(res);
  //   // this.searchConfig.forEach((item: any) => {
  //   //   item.options = this.searchResults[item.prop];
  //   // });
  //   this.startFillSearchConf();
  // }

  // private startFillSearchConf(): void {
  //   clearInterval(this.fillIntervalId);
  //   forEach(this.searchConfig, (item: any) => {
  //     item.options = [];
  //   });
  //   let index = 0;
  //   this.pushSearchResults(index);
  //   index += 50;
  //   this.fillIntervalId = setInterval(() => {
  //     this.pushSearchResults(index);
  //     // forEach(that.searchConfig, (item: any) => {
  //     //   if ((that.searchResults[item.prop] as any[]).length < index) {
  //     //     item.options.push(...(that.searchResults[item.prop] as any[]).slice(index, index + 50));
  //     //   }
  //     // });
  //     index += 50;
  //   }, 500);
  // }

  // private pushSearchResults(index: number): void {
  //   for (const item of this.searchConfig as any) {
  //     if ((this.searchResults[item.prop] as any[]).length > index) {
  //       item.options.push(
  //         ...(this.searchResults[item.prop] as any[]).slice(index, index + 50)
  //       );
  //       // forEach(this.searchForm[item.prop], (val) => {
  //       //   if (some(item.options, (op) => op.value === val)) {
  //       //     this.getLabel(item.options, val, item.prop, true);
  //       //   }
  //       // });
  //     } else {
  //       forEach(this.searchForm[item.prop], (val) => {
  //         // if (some(item.options, (op) => op.value === val)) {
  //         this.getLabel(item.options, val, item.prop, true);
  //         // }
  //       });
  //     }
  //   }
  // }

  // ================================HR CORE ORG=============================

  private async initEmployeeData() {
    this.loading = true
    let res = (await this.departmentService.getDepartmentTreeAuthorityManager()) || [];

    if (res && isArray(res)) {
      this.departmentTree = res
    }

     // 初始化选中的节点
    // this.getDefaultExpandedKeys(this.departmentTree);
    this.getDefaultCheckedKeys(this.rightdata, this.departmentTree)
    this.loading = false
  }

  public async handleCheckChange(data: any, treeStatus: any) {
    console.log('handleCheckChange', data, 'treeStatus', treeStatus);
    console.log(treeStatus.checkedKeys.includes(data.dept_id));
    if (treeStatus.checkedKeys.includes(data.dept_id)) {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) === -1) {
        this.defaultCheckedKeys.push(data.dept_id)
      }
      if (data.children && data.children.length > 0) {
        // 1. 父节点的所有子节点disabled,
        await this.disabledChildrenOfData(data.children, true)
        // 2. 判断是否在rightData, 如果在，需要从rightData中移除，并且取消该节点的选中
        await this.removeCheckedChildrenOfData(data.children)
        // 3. 计算父节点的所有子节点个数，即生效人数
        await this.getEffectUserNumbers(data)
        let effectUserNumber = this.effectMemebersCount
        this.effectMemebersCount = 0
        // 3. 并入rightData
        this.rightdata.push(Object.assign({}, { deptCode: data.dept_id, deptName: data.dept_name, type: 'dept', effectUserNumbers: effectUserNumber }))

      } else {
        // 没有子节点的节点直接并入rightData
        await this.getEffectUserNumbers(data)
        let effectUserNumber = this.effectMemebersCount
        this.rightdata.push(Object.assign({}, { deptCode: data.dept_id, deptName: data.dept_name, type: 'dept', effectUserNumbers: effectUserNumber }))
        this.effectMemebersCount = 0
      }

    } else {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) !== -1) {
        this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id), 1)
      }
      if (this.rightdata.findIndex((item: any) => item.deptCode === data.dept_id) !== -1) {
        // 1. 遍历rightData，从rightData中把取消选中的节点删除
        this.rightdata.splice(this.rightdata.findIndex((item: any) => item.deptCode === data.dept_id), 1)
        // 2. 判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
        if (data.children && data.children.length > 0) {
          await this.disabledChildrenOfData(data.children, false)
        }
      }
    }
    let newForm = cloneDeep(this.formData);
    newForm.users = this.rightdata
    this.$emit('change', newForm);
  }

  //默认展开三级
  public getDefaultExpandedKeys(departmentTree: any) {
    let newDefaultExpandedKeys: any = [];
    if (departmentTree && isArray(departmentTree) && departmentTree.length) {
      departmentTree.forEach((tree: any) => {
        if (tree.children && isArray(tree.children) && tree.children.length > 0) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }
    this.defaultExpandedKeys = newDefaultExpandedKeys;
  }

  // 初始默认选中的树节点
  public getDefaultCheckedKeys(rightData: any, departmentTree: any) {
    let newDefaultCheckedKeys: any = [], newDefaultExpandedKeys: any = [];
    if (departmentTree && isArray(departmentTree) && departmentTree.length) {
      departmentTree.forEach((tree: any) => {
        if (tree.children && isArray(tree.children) && tree.children.length > 0) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }

    rightData.forEach((item: any) => {
      if (item.type === 'dept') {
        if (newDefaultCheckedKeys.findIndex((key: any) => key === item.deptCode) === -1) {
          newDefaultCheckedKeys.push(item.deptCode)
          newDefaultExpandedKeys.push(item.deptCode)
        }
        // 判断是否有子节点，并且把子节点disabled
        this.getChildrenCheckedKeys(item.deptCode, departmentTree)
      }
    })
    console.log(this.defaultCheckedKeys)
    this.defaultCheckedKeys = newDefaultCheckedKeys;
    this.defaultExpandedKeys = newDefaultExpandedKeys;

  }

  //默认选中节点需要判断是否有子节点，并且把子节点disabled
  // public getChildrenCheckedKeys(deptId: any, itemData: any) {
  //   itemData.forEach((item: any) => {
  //     if (item.dept_id === deptId) {
  //       let children = item.children
  //       if (children && children.length > 0) {
  //         children.forEach((child: any) => {
  //           //this.$set(‘对象名’,要修改的属性名,属性值),这样新添加的属性值会被Vue监听到并且同步渲染到页面上
  //           this.$set(child, 'disabled', true)
  //           if (child.children && child.children.length > 0) {
  //             this.getChildrenCheckedKeysOfNode(child.children)
  //           }
  //         })
  //       }
  //     } else {
  //       let children = item.children
  //       if (children && children.length > 0) {
  //         this.getChildrenCheckedKeys(deptId, children)
  //       }
  //     }
  //   })
  // }

  public getChildrenCheckedKeys(deptId: any, itemData: any) {
    itemData.forEach((item: any) => {
      if (item.dept_id === deptId) {
        let children = item.children;
        if (children && children.length > 0) {
          children.forEach((child: any) => {
            this.$set(child, 'disabled', true);
            let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
            if (index == -1) {
              this.defaultDisabledDepartmentID.push(child.dept_id);
            }
            if (child.children && child.children.length > 0) {
              this.getChildrenCheckedKeysOfNode(child.children);
            }
          });
        }
      } else {
        let children = item.children;
        if (children && children.length > 0) {
          this.getChildrenCheckedKeys(deptId, children);
        }
      }
    });
  }

  public getChildrenCheckedKeysOfNode(children: any) {
    children.forEach((child: any) => {
      this.$set(child, 'disabled', true)
      let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
      if (index == -1) {
        this.defaultDisabledDepartmentID.push(child.dept_id);
      }
      if (child.children && child.children.length > 0) {
        this.getChildrenCheckedKeysOfNode(child.children)
      }
    })

  }

  // 获取有效员工数
  public async getEffectUserNumbers(data: any) {
    // let result = await this.departmentService.getMembersOfDepart({ deptId: data.id, divisionCode: ''})
    let result = await this.departmentService.getDepartMembersByID(data.dept_id);
    this.effectMemebersCount = result.length ? result.length : 0;
    console.log(result)
  }

  //
  public disabledChildrenOfData(children: any, flag: boolean) {
    if (children.length !== 0) {
      children.forEach((item: any) => {
        this.$set(item, 'disabled', flag);
        if (flag) {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index == -1) {
            this.defaultDisabledDepartmentID.push(item.dept_id);
          }
        } else {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index !== -1) {
            this.defaultDisabledDepartmentID.splice(index, 1);
          }
        }
        if (item.children && isArray(item.children) && item.children.length > 0) {
          this.disabledChildrenOfData(item.children, flag)
        } else {
          this.disabledChildrenOfData([], flag)
        }
      })
    }
  }

  public removeCheckedChildrenOfData(children: any) {
    if (children.length !== 0) {
      children.forEach((child: any) => {
        let index = this.rightdata.findIndex((item: any) => item.deptCode === child.dept_id)
        if (index !== -1) {
          this.rightdata.splice(index, 1)
          if (this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id) !== -1) {
            this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id), 1)
          }
          (this.$refs as any).tree2.setChecked(child, false, true)
        } else {
          if (child.children && child.children.length > 0) {
            this.removeCheckedChildrenOfData(child.children)
          }
        }
      })
    }
  }

  public unique(arr: []) {
    return Array.from(new Set(arr))
  }

  public async getMembersOfDepartment(dept: any) {
    this.dialogTableVisible = true
    this.currentDepartment = dept.deptName
    let result = await this.departmentService.getDepartMembersByID(dept.dept_id);
    console.log("getMembersOfDepartment",result);
    this.currentDepartMembers = result

  }

    //生效人数框里删除功能
  public deleteCurrentNodeButton(selectedNode: any) {
    console.log('selectedNode', selectedNode);
    const { deptCode, type, userId } = selectedNode;
    if (type === 'dept' && this.defaultCheckedKeys.findIndex((item: any) => item === deptCode) !== -1) {
      this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === deptCode), 1);
    }
    if (type === 'dept' && this.rightdata.findIndex((item: any) => item.deptCode === deptCode && item.type == 'dept') !== -1) {
      this.rightdata.splice(this.rightdata.findIndex((item: any) => item.deptCode === deptCode && item.type == 'dept'), 1);
      if (this.showDropSearch) {
        if (this.deptCheckList.findIndex((item: any) => item === deptCode) !== -1) {
          this.deptCheckList.splice(this.deptCheckList.findIndex((item: any) => item === deptCode), 1);
        }
      }
      //判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
      this.findAndDeleteCurrentNode(deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
    } else if (type === 'user' && this.rightdata.findIndex((item: any) => item.userId === userId && item.type == 'user') !== -1) {
      this.rightdata.splice(this.rightdata.findIndex((item: any) => item.userId === userId && item.type == 'user'), 1);
      if (this.showDropSearch) {
        if (this.memberCheckList.findIndex((item: any) => item === userId) !== -1) {
          this.memberCheckList.splice(this.memberCheckList.findIndex((item: any) => item === userId), 1);
        }
      }
    }
  }

  private findAndDeleteCurrentNode(deptCode: any, children: any) {
    if (children && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if(!this.showDropSearch){
            (this.$refs as any).tree2.setChecked(deptCode, false, true);
          }
          this.disabledChildrenOfData(item.children, false);
        } else {
          this.findAndDeleteCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private findAndCheckCurrentNode(deptCode: any, children: any) {
    if (children && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if(!this.showDropSearch){
            (this.$refs as any).tree2.setChecked(deptCode, true, true);
          }
          this.removeCheckedChildrenOfData(item.children)
          this.disabledChildrenOfData(item.children, true);
        } else {
          this.findAndCheckCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private async queryUserAndDepartByText(): Promise<void> {
    let result = await this.departmentService.searchDepartAndUserByKeywords({ keyword: this.filterText });
    //result为部门输出表deptOutputList和用户输出表userOutputList
    console.log(result);
    if (result.deptOutPutList && isArray(result.deptOutPutList)) {
      this.deptSearchList = result.deptOutPutList;
    }
    if (result.userOutputList && isArray(result.userOutputList)) {
      this.memberSearchList = result.userOutputList;
    }

    // 2. 根据rightData和list来初始化选中的节点，rightData中type=dept的需要在deptSearchList中匹配，type=user的在memberSearchList中匹配
    this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, this.rightdata)
    this.updateCheckBoxDisableValue();
  }

  public initCheckedListBySearchText(deptList: any, memberList: any, rightData: any) {
    rightData.forEach((item: any) => {
      if (item.type === 'dept') {
        let index = deptList.findIndex((dept: any) => dept.deptCode === item.deptCode)
        if (index !== -1) {
          this.deptCheckList.push(item.deptCode)
        }
      } else {
        let index = memberList.findIndex((member: any) => member.userId === item.userId)
        if (index !== -1) {
          this.memberCheckList.push(item.userId)
        }
      }
    })
  }

  public updateCheckBoxDisableValue() {
    this.deptSearchList.forEach((item: any) => {
      let index = this.defaultDisabledDepartmentID.indexOf(item.deptCode);
      if (index !== -1) {
        this.$set(item, 'disabled', true);
      } else {
        this.$set(item, 'disabled', false);
      }
    });
  }

  public onSubmit() {
    console.log(this.memberForm)
  }

  public resetForm() {
    (this.$refs as any).memberForm.resetFields()
  }

  public handleSizeChange(val: any) {
    console.log(`每页 ${val} 条`);
  }

  public handleCurrentChange(val: any) {
    console.log(`当前页: ${val}`);
  }

  public changeMemberChecked(memberItem: any) {
    // 判断选中的memberItem是否在checkedlist中，如果不在，则是取消，需要从rightData中删除该条数据；如果在，则加入rightData
    let index = this.memberCheckList.findIndex((item: any) => item === memberItem.userId)
    if (index !== -1) {
      this.rightdata.push(Object.assign({}, memberItem, { type: 'user', effectUserNumbers: 1, name: memberItem.fullName }));
    } else {
      this.rightdata.splice(this.rightdata.findIndex((item: any) => item.userId === memberItem.userId), 1)
    }
  }

  public async changeDeptChecked(dept: any) {
    this.searchLoading = true;
    let index = this.deptCheckList.findIndex((item: any) => item === dept.deptCode);
    if (index !== -1) {
      let result = await this.departmentService.getDepartMembersByID(dept.deptCode);
      console.log(result);
      let effectUserNumbersCount = result && isArray(result) && result.length ? result.length : 0;
      this.rightdata.push(Object.assign({}, {
        deptCode: dept.deptCode,
        deptName: dept.deptName,
        type: 'dept',
        effectUserNumbers: effectUserNumbersCount
      }));
      this.findAndCheckCurrentNode(dept.deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;

    } else {
      this.rightdata.splice(this.rightdata.findIndex((item: any) => item.deptCode === dept.deptCode), 1);
      this.findAndDeleteCurrentNode(dept.deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;

    }
  }




}
