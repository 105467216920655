

















import { Component, Vue, Ref } from 'vue-property-decorator';
import pharmaandonco from './pharmaandonco.vue';
import hrcoreorg from './hrCoreOrg.vue';
import { PharmaSearchConfig, OncoSearchConfig, hrCoreConfig } from '../../components/NovaGroupTransfer/index.config';
import { ElTabs } from 'element-ui/types/tabs';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import UserGroupSummary from '@/views/userGroup/UserGroupSummary.vue';
import UserGroupCustom from '@/views/userGroup/UserGroupCustom.vue';

@Component({
  components: { UserGroupSummary, UserGroupCustom, pharmaandonco, hrcoreorg }
})
export default class UserGroup extends Vue {
  public activeName: string = '0';
  public pharmaSearchConfig = PharmaSearchConfig;
  public oncoSearchConfig = OncoSearchConfig;
  public hrCoreConfig = hrCoreConfig; // HRCore 需要添加的
  @Ref('tabsEl') private tabsEl!: ElTabs;
  public async mounted(): Promise<void> {
    // await this.$nextTick();
    // 默认展示第几个tab页
    // this.activeName = (this.tabsEl as any).panes[0].name;
  }
}
