






























































import { Component, Vue } from 'vue-property-decorator';
import UserGroupDetail from '@/views/userGroup/UserGroupDetail.vue';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { DivisionOrg } from '@/views/userGroup/config';
import { UserGroupService } from '@/services/user-group-service';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DialogService } from '@/services/common/dialog.service';
import UploadUserGroup from '@/views/userGroup/dialog/UploadUserGroup.vue';
import { uuid } from '@/services/utils';
import UserGroupDetailTable from '@/components/UserGroup/UserGroupDetailTable.vue';
import UserGroupTagGroup from '@/components/UserGroup/UserGroupTagGroup.vue';
import { cloneDeep } from 'lodash';

@Component({ components: { UserGroupDetailTable, UserGroupDetail, UserGroupTagGroup } })
export default class UserGroupSummary extends Vue {
  private loading: boolean = false;
  private searchText: string = '';
  public allGroups: any[] = [];
  private activeUserGroup: any[] = [];

  private isFilteredUserGroup: boolean = false;
  private isSelectedFilterUserGroupShow: boolean = false;
  // public filteredUserGroups: any[] = [];
  public filteredUserList: any = [];
  // private selectedFilteredUserGroup: any = {};


  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!:DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<any> {
    await this.initAllUserGroups();
  }

  public async modifyUserGroupConfirm(): Promise<void> {
    this.loading = true;
    try {
      this.allGroups = await this.userGroupService.getAllUserGroups();
    } finally {
      this.loading = false;
    }
  }

  public async initAllUserGroups(): Promise<void> {
    this.loading = true;
    try {
      this.allGroups = await this.userGroupService.getAllUserGroups();
      this.activeUserGroup = this.allGroups;
    } finally {
      this.loading = false;
    }
  }

  private async getUserList(userGroupIdList: any[]) {
    let userGroupList = await this.userGroupService.retrieveUserGroupEffectiveUserList(userGroupIdList);

  }

  private async getGroups(type: DivisionOrg, name?: string) {
    try {
      const res = await this.userGroupService.getusergroup({
        division: type,
        name: name ? name : ''
      });
      res.forEach((user:any) => {
        user.type = type
      })
      return res || [];
    } finally {
      this.loading = false;
    }
  }

  private async searchFilteredRules() {
    if (this.searchText !== '') {
      let filteredData = await this.userGroupService.searchUserGroupsAndUsersByKeyword({ keyword: this.searchText ? this.searchText : '' });
      console.log('filteredData', filteredData);
      // this.filteredUserGroups = filteredData.userGroupVOList;
      this.allGroups = filteredData.userGroupVOList;
      this.activeUserGroup = filteredData.userGroupVOList;
      this.filteredUserList = filteredData.userVoList;
      // this.selectedFilteredUserGroup ={};
      this.isSelectedFilterUserGroupShow = false;
      this.isFilteredUserGroup = true;
    } else {
      await this.initAllUserGroups();
      this.isFilteredUserGroup = false;

    }
  }

  private async createNewGroup(): Promise<void> {
    const key = uuid();
    const res = await this.dialogService.open(
      '创建应用权限规则',
      ModifyUserGroup,
      {
        config: [
          {}
        ],
        type:'hr core',
        key
      },
      {
        height: 'auto',
        key
      }
    );
    console.log(res);
    if (res.action === CloseStatus.confirm) {
      await this.initAllUserGroups();
      let activeUserGroup = [this.allGroups[0]];
      activeUserGroup[0].refreshTime = Date.now();
      this.activeUserGroup = activeUserGroup;
      this.isFilteredUserGroup = false;
      this.searchText = '';
      // 重新调取查询
      // this.getUserGroups(type);
    }
  }

  private async clearSearch(val: any) {
    if (!this.searchText){
      await this.initAllUserGroups();
      this.isFilteredUserGroup = false;
    }
  }

  private changeActiveUserGroup(val: any) {
    console.log('changeActiveUserGroup', val, Date.now());
    let newVal = cloneDeep(val);
    newVal.refreshTime = Date.now();
    if (this.isFilteredUserGroup) {
      this.isSelectedFilterUserGroupShow = true;
    }
    this.activeUserGroup = [newVal];
  }

  public async refreshUserGroupList(value:any){
    await this.initAllUserGroups();
    let activeUserGroup = [this.allGroups[0]];
    activeUserGroup[0].refreshTime = Date.now();
    this.activeUserGroup = activeUserGroup;
    this.isFilteredUserGroup = false;
  }

}
