







































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import AddManagerUser from '@/components/addManagerUser/addManagerUser.vue';
import { I18nService } from '@cds/i18n';
import NovaPositionTransfer from '../../components/NovaGroupTransfer/NovaPositionTransfer.vue';
import { rulesConfig } from '../../components/NovaGroupTransfer/index.config';
import { ElForm } from 'element-ui/types/form';
import { isArray } from 'lodash';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { IAdminUser } from '../manager';

@Component({
  components: { NovaPositionTransfer }
})
export default class Manager extends Vue {
  public loading: boolean = false;
  public formLoading: boolean = false;
  public rules: any = rulesConfig;
  public searchText: string = '';
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  private treeList: any[] = [];
  private menuActiveIndex: string = '1';
  private formData: any = { structureFlag: 'N' };
  private currentUser!: IAdminUser;
  @Ref('novatransfer')
  private novatransferref!: any;

  @Prop({ required: true })
  private config!: Dict<any>;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  @Ref('ruleForm') private ruleForm!: ElForm;
  @Prop({ required: true })
  private type!: string;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public created(): void {
    this.currentUser = this.cacheSvc.get('user');
  }

  public async mounted(): Promise<void> {
    await this.getTree();
    if (this.treeList && this.treeList.length > 0) {
      this.menuActiveIndex = this.treeList[0].id.toString();
      await this.handleSelectMenu(this.menuActiveIndex);
      // await this.getUserGroupDetail(this.menuActiveIndex);
    }
  }

  // 添加管理员
  public async addManagerUser(): Promise<void> {
    const res = await this.dialog.open(
      '选择管理员',
      AddManagerUser,
      {
        list: this.formData.admins
      },
      {
        height: 'auto',
        width: 'auto'
      }
    );
    if (res.action === CloseStatus.confirm) {
      this.formData.admins = res.result;
    }
  }

  // 删除标签tag
  public handleTagDelete(obj: any, data: any, key: string): void {
    const index = data.admins.findIndex((item: any) => item[key] === obj[key]);
    data.admins.splice(index, 1);
  }
  // 保存roles
  public handleChangeRoles(val: Dict<any>): void {
    this.formData.userGroupRoles = val;
  }

  // 删除菜单
  public async deleteMenu(id: number): Promise<void> {
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.userGroupService.deleteUserGroup(id);
      await this.getTree();
      if (id.toString() === this.menuActiveIndex) {
        this.menuActiveIndex = this.treeList[0].id.toString();
        this.getUserGroupDetail(this.menuActiveIndex);
      }
    } catch (e) {
      this.$notify.info({
        title: '取消',
        message: '删除取消'
      });
    }
  }

  public judgestructureFlag(type: string): void {
    this.formData.structureFlag = type;
  }

  // 添加用户分组
  public addUsergroup(): void {
    // 创建新的用户分组 id设置为-1 如果已经创建了一个新的用户分组且未保存 那么不再push新的用户分组
    const index = this.treeList.findIndex((item: any) => item.id === -1);
    if (index === -1) {
      this.menuActiveIndex = index.toString();
      this.treeList.push({
        id: -1,
        name: '新增用户分组'
      });
      this.initFormData();
      if (this.novatransferref) {
        this.novatransferref.getSearchOption({});
      }
    } else {
      this.menuActiveIndex = index.toString();
      this.handleSelectMenu('-1');
    }
  }

  //  保存
  public async submitSaved(): Promise<void> {
    // 权限分组人员必须填写
    if (await this.ruleForm.validate()) {
      if (
        this.formData.users &&
        isArray(this.formData.users) &&
        this.formData.users.length > 0
      ) {
        if (this.formData.id) {
          await this.userGroupService.putUserGroup(this.formData);
          await this.getTree();
          await this.getUserGroupDetail(this.menuActiveIndex);
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
        } else {
          await this.submitSaveAS();
          await this.getTree();
          this.handleSelectMenu(this.treeList[0].id.toString());
        }
      } else {
        this.$notify({
          title: '提示',
          message: '生效人数不能为空',
          type: 'error'
        });
      }
    }
  }

  // 获取树节点
  private async getTree(name?: string) {
    try {
      this.loading = true;
      const res = await this.userGroupService.getusergroup({
        division: this.type === 'Pharma' ? 'pharmaOrg' : 'oncoOrg',
        name: name ? name : ''
      });
      this.treeList = res || [];
    } finally {
      this.loading = false;
    }
  }

  // 获取用户分组详细详情
  private async getUserGroupDetail(id: string) {
    try {
      this.formLoading = true;
      const res = await this.userGroupService.getusergroupById(id);
      this.formData = res;
    } finally {
      this.formLoading = false;
    }
  }

  // 菜单切换
  private async handleSelectMenu(index: string): Promise<void> {

    this.ruleForm.clearValidate();
    // -1状态为新建分组
    if (this.menuActiveIndex === '-1') {
      this.treeList.splice(this.treeList.length, 1);
    }
    this.menuActiveIndex = index;
    // index === -1 为新创建的用户分组
    if (index !== '-1') {
      await this.getUserGroupDetail(index);
    } else if (index === '-1') {
      // 初始化筛选条件对象
      this.initFormData();
    }
    if (this.novatransferref) {
      this.novatransferref.getSearchOption(this.formData.userGroupRoles);
    }
  }

  // 初始化form表单
  private initFormData(): void {
    // 初始化筛选条件对象
    const userGroupRoles: Dict<any> = {};
    this.config.forEach((item: Dict<any>) => {
      userGroupRoles[item.prop] = [];
    });
    this.formData = {
      users: [],
      admins: [
        {
          disabledFlag: 1,
          email: this.currentUser.email,
          employeeId: this.currentUser.employeeId || '',
          fullName: this.currentUser.fullName,
          id: this.currentUser.id
        }
      ],
      structureFlag: 'N',
      type: this.type === 'Pharma' ? 'pharmaOrg' : 'oncoOrg',
      userGroupRoles
    };
  }

  // 另存为新的分组
  private async submitSaveAS(): Promise<void> {
    if (await this.ruleForm.validate()) {
      if (
        this.formData.users &&
        isArray(this.formData.users) &&
        this.formData.users.length > 0
      ) {
        this.formData.type = this.type === 'Pharma' ? 'pharmaOrg' : 'oncoOrg';
        await this.userGroupService.postUserGroup(this.formData);
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success'
        });
        await this.getTree();
        this.handleSelectMenu(this.treeList[0].id.toString());
      } else {
        this.$notify({
          title: '提示',
          message: '生效人数不能为空',
          type: 'error'
        });
      }
    }
  }
}
