







import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import { cloneDeep, debounce } from 'lodash';
import UserGroupDetailTable from '@/components/UserGroup/UserGroupDetailTable.vue';
@Component({
  components: { UserGroupDetailTable }
})
export default class UserGroupDetail extends Vue {
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  private userGroupInitUpdateDebounce = debounce(this.userGroupInitUpdate, 500);
  private userGroupDetailLoading: boolean = false;
  private userGroup: any = {};
  private userList: any[] = [];
  
  @Prop({ required: false })
  private groupId: any;

  @Prop({ required: false })
  private isBtnShow: any;

  @Model('change', {
    type: Array, default: () => {
    }
  })
  public userGroupInit: any;

  @Watch('userGroupInit', { deep: true })
  public handleUserGroupChange(userGroupInit: any[]) {
    this.userGroupInitUpdateDebounce(userGroupInit)
  }

  private async userGroupInitUpdate(userGroupInit: any): Promise<void> {
    if (userGroupInit.length === 1) {
      if (this.userGroup.customGroupId !== userGroupInit[0].customGroupId || this.userGroup.refreshTime !== userGroupInit[0].refreshTime) {
        await this.getUserGroupConditionAndUsers(userGroupInit[0].customGroupId);
      }
      this.userGroup = userGroupInit[0];
    }
  }

  public async mounted(): Promise<void> {
    this.userGroup = cloneDeep(this.userGroupInit)
    if (this.userGroupInit[0] && this.userGroupInit[0].customGroupId) {
      await this.getUserGroupConditionAndUsers(this.userGroupInit[0].customGroupId);
    }
  }

  //点击组查询人员列表
  public async getUserGroupConditionAndUsers(id: any): Promise<any> {
    this.userGroupDetailLoading = true;
    try {
      let res = await this.userGroupService.getCustomGroupUsers({customGroupId: id});
      if (res) {
        this.userList = res.users;
      }
    } catch {
      this.userList = [];
    } finally {
      this.userGroupDetailLoading = false;
    }
  }
}
