
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NovaTransfer extends Vue {

  @Prop({ required: true })
  private index: any;

  @Prop({ required: true })
  private item: any;

  public async mounted() {

  }

  public getMembersOfDepartment(data: any) {
    this.$emit('getMembers', data)
  }

  public deleteItem(data:any){
    console.log('deleteItem',data)
    this.$emit('deleteItem',data)
  }


}
